import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'
import Avatar from '@components/Avatar'

const styles = {
  wrapper: {
    display: [`none`, null, `block`],
    position: `relative`,
    textAlign: `center`,
  },
}

export default () => {
  const data = useStaticQuery(heroQuery)
  const image = data && data.avatar && data.avatar.childImageSharp

  return (
    <Box sx={styles.wrapper}>
      <Avatar
        avatar={image}
        loading='eager'
        alt="Ioannis Ioannou"
      />
    </Box>
  )
}

const heroQuery = graphql`
  query HeroQuery {
    avatar: file(absolutePath: { regex: "/hero.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        regular: gatsbyImageData(
          width: 240
          height: 240
          transformOptions: { cropFocus: NORTH },
          placeholder: NONE
        )
      }
    }
  }
`
