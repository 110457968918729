import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  occupation: {
    mb: 4
  },
}

export default () => (
  <>
    <Heading variant='h1' as="h1">
      Hi, I'm Ioannis.
    </Heading>
    <Heading variant='h2' sx={styles.occupation}>
      Software Engineer | London, UK
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to='/contact/'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about/'>
        About Me
      </Button>
    </Box>
  </>
)
