import React from 'react'
import { Hero, Layout, Main, PreFooter, Stack } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import HeroComponent from '../components/Hero/Hero'

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { basePath } = {} } = props

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal']}
            title=''
            nodes={paginatedPosts.nodes}
            omitMedia
            omitAuthor
            omitCategory
          />
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
